/*--------------------------------------------------------------
# Clients Details
--------------------------------------------------------------*/
.clients-title{
  padding: 10px 0 0;
}

.clients {
  background-color: lighten($color: $secondary, $amount: 70);;
  padding-top: 20px;
  
  .clients-details-slider {
    img {
      width: 100%;
    }
    .swiper-pagination {
      margin-top: 20px;
      position: relative;

      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        background-color: #fff;
        opacity: 1;
        border: 1px solid var(--color-primary);
      }

      .swiper-pagination-bullet-active {
        background-color: var(--color-primary);
      }
    }

  }

  .clients-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba($secondary, .1);

    h3 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid lighten($secondary, 70);
    }

    ul {
      list-style: none;
      padding: 0;
      font-size: 15px;

      li +li {
        margin-top: 10px;
      }
    }
  }

  .clients-description {
    padding-top: 30px;
    
    h2 {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 20px;
      color: var(--color-secondary);
    }

    p {
      padding: 0;
    }
  }

  .swiper-container {
    position:relative;
    width: 100%;
  }

  .swiper-container > .swiper-slide__content {
    position:absolute;
    top:0;
  }

  .swiper-slide {
    display:flex;
    align-items: center;
    justify-content:center;
    transition:all 200ms linear;
  }
}