/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 24px 0;
  @media (max-width: 1200px) {
    padding: 12px 0;
  }

  &.sticked {
    /*! purgecss ignore current */
    background: rgba(white, 0.9);
    padding: 12px 0;
  }

  .logo {
    background: url("./images/logos/logo-white.webp") top center;
    width: 176px;
    min-width: 176px;
    height: 40px;
    background-size: cover;
    img {
      max-height: 40px;
      margin-right: 6px;
    }
  }

  .logo-color {
    /*! purgecss ignore current */
    background: url("./images/logos/logo-color.webp") top center;
    width: 176px;
    min-width: 176px;
    height: 40px;
    background-size: cover;
    img {
      max-height: 40px;
      margin-right: 6px;
    }
  }

  .btn-rounded {
    /*! purgecss ignore current */
    border-radius: 50px!important;
  }

}
