/*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.call-to-action {
  background: linear-gradient(rgba($secondary, .8), rgba($secondary, .8)), url("./images/panel_clientes.webp") center center;
  background-size: cover;
  padding:  100px 0;

  @media (min-width: 1365px) {
    background-attachment: fixed;
  }

  h3 {
    color: #fff;
    font-size: 28px;
    margin-bottom: 25px;
    font-weight: 700;
  }

  p {
    color: #fff;
    margin-bottom: 25px;
  }

  .cta-btn {
    font-family: var(--font-default);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    color: #fff;
    background: darken($primary, 10);
    &:hover {
      background: var(--color-primary);
    }
  }
}
