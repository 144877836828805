/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 100vh;
  background: url("./images/portada-landing.webp") top center;
  background-size: cover;
  background-position: left;
  position: relative;
  padding: 120px 0;
  z-index: 3;

  @media (max-width: 1034px) {
    background-position: center;
  }

  @media (min-width: 1034px) {
    &:after {
      position: absolute;
      content: "";
      width: 38vw;
      background: var(--color-secondary);
      top: 0;
      bottom: 0;
    }
  }

  &:before {
    position: absolute;
    content: "";
    /*background: rgba($secondary, 0.6);*/
    background: rgba(27, 47, 69, 0.6);
    inset: 0;
    @media (max-width: 1034px) {
      /*background: rgba($secondary, 0.7);*/
      background: rgba(27, 47, 69, 0.7);
    }
  }

  .container {
    z-index: 1;
  }

  @media (min-width: 1365px) {
    background-attachment: fixed;
  }

  h2 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    font-family: var(--font-secondary);
  }

  blockquote {
    color: #fff;
    padding-left: 20px;
    font-size: 20px;
    font-family: var(--font-primary);
    border-left: 2px solid var(--color-primary);
    margin: 40px 0;

    span {
      font-weight: bold;
      font-size: 22px;
    }
  }

  .btn-get-started {
    font-family: var(--font-secondary);
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: darken($primary, 10);
    &:hover {
      background: var(--color-primary);
    }
  }

  .btn-watch-video {
    font-size: 16px;
    transition: 0.5s;
    margin-left: 25px;
    color: #fafafa;
    font-weight: 600;

    i {
      color: var(--color-primary);
      font-size: 32px;
      transition: 0.3s;
      line-height: 0;
      margin-right: 8px;
    }

    &:hover {
      color: #fff;
      i {
        color: lighten($primary, 10);
      }
    }
  }

  @media (max-width: 640px) {
    h2 {
      font-size: 36px;
      line-height: 1;
    }

    .btn-get-started, .btn-watch-video {
      font-size: 13px;
    }
  }

  /*! purgecss start ignore */
  .is-hidden {
    animation: text-fade-out 0.6s;
  }

  .is-visible {
    animation: text-fade-in 0.6s;
  }

  @keyframes text-fade-in {
    0% {
      opacity: 0;
      filter: blur(4px);
    }
    25% {
      opacity: 0.25;
      filter: blur(3px);
    }
    50% {
      opacity: 0.50;
      filter: blur(2px);
    }
    75% {
      opacity: 0.75;
      filter: blur(1px);
    }
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }

  @keyframes text-fade-out {
    0% {
      opacity: 1;
      filter: blur(0);
    }
    25% {
      opacity: 0.75;
      filter: blur(1px);
    }
    50% {
      opacity: 0.50;
      filter: blur(2px);
    }
    75% {
      opacity: 0.25;
      filter: blur(3px);
    }
    100% {
      opacity: 0;
      filter: blur(4px);
    }
  }
  /*! purgecss end ignore */

}
