/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
#maintenance{
  padding-top: 0;
}

#preventivo{
  padding-top: 100px;
}

#correctivo{
  padding-top: 100px;
}

.portfolio-details {

  .portfolio-details-slider {
    img {
      width: 100%;
    }
    .swiper-pagination {
      margin-top: 20px;
      position: relative;

      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        background-color: #fff;
        opacity: 1;
        border: 1px solid var(--color-primary);
      }

      .swiper-pagination-bullet-active {
        background-color: var(--color-primary);
      }
    }

  }

  .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba($secondary, .1);

    h3 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid lighten($secondary, 70);
    }

    ul {
      list-style: none;
      padding: 0;
      font-size: 15px;

      li +li {
        margin-top: 10px;
      }
    }
    
  }

  .portfolio-description {
    padding-top: 30px;
    
    h2 {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 20px;
      color: var(--color-secondary);
    }

    p {
      padding: 0;
    }
  }

  .portfolio-bullets {
    padding-top: 30px;
    
    h4 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 20px;
      color: var(--color-secondary);
    }

    p {
      padding: 0;
    }
    
    ul{
      padding-left: 0;
    }

    .live{ 
      overflow:hidden;

      > li{ 
        list-style:none;
        position:relative; 
        padding:0 0 0 2em;
        margin:0 0 .5em 10px;
        transition:.12s;
        
        &::before{
          position:absolute;
          content:'\2022'; 
          font-family:Arial;
          top:0;
          left:0;
          text-align:center; 
          font-size:2em; 
          opacity:.5;
          line-height:.75; 
          transition:.5s;
        }
        
        &:hover{
          color:#4C4C4C;
          &::before{
            transform:scale(2); 
            opacity:1; 
            text-shadow:0 0 4px;
            transition:.1s;
          }
        }
      }
    }
  }

}
