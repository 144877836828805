/*--------------------------------------------------------------
# Services List Section
--------------------------------------------------------------*/
.services-list, .how-it-works {
  .service-item, .how-it-works-item {
    position: relative;
    .icon {
      i, svg {
        font-size: 32px;
        line-height: 0;
        margin-right: 20px;
        color: lighten($secondary, 20);
      }
    }
  
    .title {
      font-weight: 700;
      margin-bottom: 5px;
      font-size: 18px;
      color: var(--color-secondary);
        a {
        color: var(--color-secondary);
        &:hover {
          color: lighten($secondary, 20);
        }
      }
    }
  
    .description {
      line-height: 24px;
      font-size: 14px;
      margin-bottom: 0;
    }
  
    .see-more {
      font-size: 14px;
    }
  }
}

.services-list {
  background-color: lighten($secondary, 74);
}
