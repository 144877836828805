/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.plans {
  text-align: center;
  padding-bottom: 30px;
  
  h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 20px;
    color: var(--color-primary);
  }

@import url('https://fonts.googleapis.com/css?family=Open+Sans');

* {
	box-sizing: border-box;
}

body {
	background-color: #f6f5f7;
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 50px;
}

.text-center {
	text-align: center;
}

.pricing-box-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.pricing-box {
	background-color: adjust-color($color: $secondary, $alpha: -0.98);
	border-radius: 30px;
	flex: 1;
	margin: 2%;
	min-width: 250px;
	max-width: 350px;
	border: solid 1px adjust-color($color: $secondary, $alpha: -0.9);
}

.pricing-box h2 h3 h4 h5 {
	text-transform: uppercase;
}

.hours {
	margin: 24px 0;
	font-size: 30px;
	font-weight: 900;
}

.hours sub, .price sup {
	font-size: 16px;
	font-weight: 500;
}

.price {
	margin: 24px 0;
	font-size: 30px;
	font-weight: 900;
	color: $primary;
}

.price sub, .price sup {
	font-size: 16px;
	font-weight: 500;
	// color: white;
}

.price span {
	font-size: 16px;
	font-weight: 500;
	position: absolute;
	bottom: 64px;
}

/*
.features-list {
	padding: 0;
	list-style-type: none;
}

.features-list li {
	font-weight: 100;
	padding: 12px 0;
	font-weight: 100;
}

.features-list li:not(:last-of-type) {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}*/

.btn-primary {
	border-radius: 25px;
	border: none;
	background-color: darken($primary, 10);
	color: #fff;
	cursor: pointer;
	padding: 10px 15px;
	margin-top: 20px;
	transition: all 0.1s ease-in-out;

	&.btn-offset {
		position: absolute;
		bottom: -25px;
    left: 39%;
	}
}

.btn-primary:hover {
	box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.5);
	transform: translateY(-3px);
	background-color: $primary;
	color: #fff;
}

.pricing-image {
	height: 250px;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;

	&.office-image {
		background-image: url('./images/plan-office.webp');
		mask-image: linear-gradient(to bottom, rgba(0,0,0,1), transparent 100%);
		-webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1), transparent 100%);
		background-size: cover;
		background-position: center center;
		color: #ffffff;
	}
	
	&.warehouse-image {
		background-image: url('./images/plan-warehouse-3.webp');
		mask-image: linear-gradient(to bottom, rgba(0,0,0,1), transparent 100%);
		-webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1), transparent 100%);
		background-size: cover;
		background-position: center center;
		color: #ffffff;
	}
	
	&.custom-image {
		background-image: url('./images/plan_medida.webp');
		mask-image: linear-gradient(to bottom, rgba(0,0,0,1), transparent 100%);
		-webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1), transparent 100%);
		background-size: cover;
		background-position: center center;
		color: #ffffff;
	}
}


/*.pricing-box-bg-image .features-list li {
	border-bottom-color: rgba(255, 255, 255, 1);
}*/

.features-list {
    margin-bottom: 30px;
    ul{
      list-style: none;
      padding: 0;
      margin: 0;

      svg {
        margin-right: 4px;
        color: var(--color-primary);
        font-size: 16px;
        line-height: 1;
      }

      li {
        padding: 2px 0;
        &:first-child {
          padding-top: 0;
        }
        display: flex;
        align-items: center;
      }

      a {
        color: lighten($secondary, 15);
        transition: 0.3s;
        display: inline-block;
        line-height: 1;
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }

.pricing-info{
    padding: 30px;
    position: relative;
    z-index: 1;
    background-clip: padding-box!important;
		border-radius: 30px;
}

 /*
  #plan-office{
    background: url("./images/plan-office.png") top center;
  	height: 206px;
    background-size: cover;
  }

  #plan-warehouse{
    background: url("./images/plan-warehouse.png") top center;
  	height: 206px;
    background-size: cover;
  }

  #plan-items{
  	justify-content: center;
  }*/
}