@charset "UTF-8";

@font-face {
  font-family: "icons";
  // src:url("../fonts/icons.eot");
  // src:url("../fonts/icons.eot?#iefix") format("embedded-opentype"),
  //   url("../fonts/icons.woff") format("woff"),
  //   url("../fonts/icons.ttf") format("truetype"),
  //   url("../fonts/icons.svg#icons") format("svg");
  src: url("./fonts/icons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: sub;
}

.icon-twit-01:before {
  content: "\61";
}
.icon-vinieta-02:before {
  content: "\62";
}
.icon-mujer:before {
  content: "\63";
}
.icon-extra-heladera:before {
  content: "\64";
}
.icon-extra-ventanas:before {
  content: "\65";
}
.icon-inst-1:before {
  content: "\66";
}
.icon-extra-horno:before {
  content: "\67";
}
.icon-user:before {
  content: "\68";
}
.icon-info-2:before {
  content: "\69";
}
.icon-gplus-1-1:before {
  content: "\6a";
}
.icon-pintura-2-1:before {
  content: "\6b";
}
.icon-sanitaria-2:before {
  content: "\6c";
}
.icon-limpieza:before {
  content: "\6d";
}
.icon-vinieta-03:before {
  content: "\6e";
}
.icon-direccion:before {
  content: "\6f";
}
.icon-menu-02:before {
  content: "\70";
}
.icon-pago:before {
  content: "\71";
}
.icon-agendar:before {
  content: "\72";
}
.icon-hombre:before {
  content: "\73";
}
.icon-aire:before {
  content: "\74";
}
.icon-delete:before {
  content: "\75";
}
.icon-a-la-mano-21:before {
  content: "\76";
}
.icon-borrar:before {
  content: "\77";
}
.icon-twit-1:before {
  content: "\78";
}
.icon-vinieta-04:before {
  content: "\79";
}
.icon-editar:before {
  content: "\7a";
}
.icon-face-1:before {
  content: "\41";
}
.icon-info:before {
  content: "\42";
}
.icon-tecnico-2:before {
  content: "\43";
}
.icon-tele:before {
  content: "\44";
}
.icon-mudanza:before {
  content: "\45";
}
.icon-electricista-2:before {
  content: "\46";
}
.icon-lavanderia:before {
  content: "\47";
}
.icon-muebles:before {
  content: "\48";
}
.icon-vinieta-01:before {
  content: "\49";
}
.icon-check-01:before {
  content: "\4a";
}
.icon-face-01:before {
  content: "\4b";
}
.icon-extra-lava-ropa:before {
  content: "\4c";
}
.icon-flecha:before {
  content: "\4d";
}
.icon-gplus-01:before {
  content: "\4e";
}
.icon-detalles:before {
  content: "\4f";
}
.icon-herramientas:before {
  content: "\50";
}
.icon-cadeteria:before {
  content: "\51";
}
.icon-sanitaria2:before {
  content: "\52";
}
.icon-sanitaria2-1:before {
  content: "\53";
}
.icon-aire2:before {
  content: "\54";
}
.icon-lavanderia2:before {
  content: "\55";
}
.icon-envio:before {
  content: "\56";
}
.icon-inst-tv:before {
  content: "\57";
}
.icon-inst-tv2:before {
  content: "\58";
}
.icon-inst-tv3:before {
  content: "\59";
}
.icon-inst-tv4:before {
  content: "\5a";
}
.icon-tv5:before {
  content: "\30";
}
.icon-mudanza2:before {
  content: "\31";
}
.icon-tintoreria:before {
  content: "\32";
}
.icon-electricista:before {
  content: "\33";
}
.icon-tintoreria2:before {
  content: "\34";
}
.icon-icono-electricista2:before {
  content: "\35";
}
.icon-lavanderia3:before {
  content: "\36";
}
.icon-tintoreria3:before {
  content: "\37";
}
.icon-electricista3:before {
  content: "\38";
}
.icon-micro1:before {
  content: "\39";
}
.icon-estante:before {
  content: "\21";
}
.icon-mueble:before {
  content: "\22";
}
.icon-mueble2:before {
  content: "\23";
}
.icon-mueble3:before {
  content: "\24";
}
.icon-mueble4:before {
  content: "\25";
}
.icon-mueble5:before {
  content: "\26";
}
.icon-mueble6:before {
  content: "\27";
}
.icon-desk:before {
  content: "\28";
}
.icon-limpieza1:before {
  content: "\29";
}
.icon-limpieza2:before {
  content: "\2a";
}
.icon-limpieza4:before {
  content: "\2b";
}
.icon-limpieza5:before {
  content: "\2c";
}
.icon-vacuum-cleaner-2:before {
  content: "\2d";
}
.icon-ingeniero:before {
  content: "\2e";
}
.icon-taladro:before {
  content: "\2f";
}
.icon-taladro1:before {
  content: "\3a";
}
.icon-taladro2:before {
  content: "\3b";
}
.icon-mueble7:before {
  content: "\3c";
}
.icon-mueble8:before {
  content: "\3d";
}
.icon-mueble9:before {
  content: "\3e";
}
.icon-limpiezaauto:before {
  content: "\3f";
}
.icon-limpiezaauto1:before {
  content: "\40";
}
.icon-cama1:before {
  content: "\5b";
}
.icon-cama2:before {
  content: "\5d";
}
.icon-cama3:before {
  content: "\5e";
}
.icon-cama4:before {
  content: "\5f";
}
.icon-moquete:before {
  content: "\60";
}
.icon-alfombra1:before {
  content: "\7b";
}
.icon-alfombra2:before {
  content: "\7c";
}
.icon-alfombra3:before {
  content: "\7d";
}
.icon-lavanderia2-1:before {
  content: "\7e";
}
.icon-micro2:before {
  content: "\5c";
}
.icon-micro3:before {
  content: "\e000";
}
.icon-cortina:before {
  content: "\e001";
}
.icon-cortina2:before {
  content: "\e002";
}
.icon-cortina3:before {
  content: "\e003";
}
.icon-cerrajero1:before {
  content: "\e004";
}
.icon-cerrajero2:before {
  content: "\e005";
}
.icon-cerrajero3:before {
  content: "\e006";
}
.icon-phone1:before {
  content: "\e007";
}
.icon-phone2:before {
  content: "\e008";
}
.icon-phone3:before {
  content: "\e009";
}
.icon-control:before {
  content: "\e00a";
}
.icon-centralizacion:before {
  content: "\e00b";
}
.icon-flexibilidad:before {
  content: "\e00c";
}
.icon-calidad:before {
  content: "\e00d";
}
.icon-centralizacion2:before {
  content: "\e00e";
}
.icon-stander:before {
  content: "\e00f";
}
.icon-stander2:before {
  content: "\e010";
}
.icon-bleach:before {
  content: "\e011";
}
.icon-cleaning:before {
  content: "\e012";
}
.icon-painter:before {
  content: "\e013";
}
