/*--------------------------------------------------------------
# How it works Section
--------------------------------------------------------------*/
.how-it-works {
  .how-it-works-image {
    z-index:100;
    position: relative;
    height: 400px;
    width: auto;
    margin-bottom: 30px;
    background-size: cover;
    &.step-1 {
      background-image: url("./images/how_it_works/alta.webp");
    }
    &.step-2 {
      background-image: url("./images/how_it_works/coordinacion.webp");
    }
    &.step-3 {
      background-image: url("./images/how_it_works/servicio.webp");
    }
    &.step-4 {
      background-image: url("./images/how_it_works/listo.webp");
    }
  }

  .title {
    color: var(--color-secondary);
  }

  .step-wrapper {
    display: flex;
    justify-items: center;
    align-items: flex-end;
  }

  .step-number {
    display: flex;
    background: lighten($primary, 40);
    margin: 0 auto -20px auto;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 25%;
    font-weight: 500;
    font-size: 24px;
    color: $secondary;
  }
}
