/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features {

  .services-div {
    margin-bottom: 80px;
  }
  
  @media screen and (max-width: 992px) {
    .services-div {
      margin-bottom: unset;
    }
  }

  padding-bottom: 0;

  h3 {
    color: var(--color-secondary);
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 80px;
  }

  .icon-pointer {
    cursor: pointer;
  }

  .icon-list {
    i {
      margin-right: 10px;
      font-size: 30px;
      line-height: 0;
    }

    span {
      font-size: 20px;
      color: lighten($default, 10);
    }
  }

  .services-list-div {
    margin-left: 125px;
  }

  .services-slider-div {
    vertical-align: middle;
    display: flex;
    margin-left: 80px;
  }
  
  @media screen and (max-width: 992px) {
    .services-slider-div {
      margin-top: 125px;
      margin-left: unset;
      margin-bottom: 200px;
    } 
    
    .services-list-div {
      margin-left: unset;
    }

    .icon-pointer{
      width: 250px;
    }

    .services-list-row{
      justify-content: center !important;
    }
  }

  .phone-wrap {
    background: url("./images/tablet.webp") top center;
    // min-height: 66vh; <--- REVISAR PANTALLA GRANDE
    min-height: 45vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 0;
    top: -34px;
    width: 550px;

    @media (max-width: 768px) {
      position: relative;
    }
    @media (max-width: 768px) {
      width: 75%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  @media screen and (max-width: 992px) {
    .phone-wrap {
      min-height: 50vh;
    }
  }

  .details {
    margin-top: 80px;
    padding: 120px 0;
    background-color: lighten($secondary, 74);

    h4 {
      color: var(--color-secondary);
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      font-size: 15px;
    }

    .btn-get-started {
      font-family: var(--font-primary);
      display: inline-block;
      font-weight: 500;
      font-size: 15px;
      letter-spacing: 1px;
      padding: 10px 32px;
      border-radius: 50px;
      transition: 0.5s;
      color: #fff;
      background: darken($primary, 10);
      &:hover {
        background: var(--color-primary);
      }
    }
  }
  
  @media screen and (max-width: 992px) {
    .details {
      padding: 130px 0 20px;
    }
  }

  .features-list {
    margin-bottom: 30px;
    ul{
      list-style: none;
      padding: 0;
      margin: 0;

      svg {
        margin-right: 4px;
        color: var(--color-primary)!important;
        font-size: 16px;
        line-height: 0;
      }

      li {
        padding: 2px 0;
        &:first-child {
          padding-top: 0;
        }
        display: flex;
        align-items: center;
      }

      a {
        color: lighten($secondary, 15);
        transition: 0.3s;
        display: inline-block;
        line-height: 1;
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }

  .technician {
    background: url("./images/technician.webp") top center;
    min-height: 500px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  // .phone-wrap {
  //   will-change: transform;
  //   transform-style: preserve-3d;
  // }

  // .phone-wrap-animation {
  //   display: inline-block;
  //   animation: translate 0.5s linear;
  //   animation-iteration-count: 1;
  //   animation-fill-mode: both;
  // }
  
  // @keyframes translate {
  //   0% {
  //     transform: translateY(0);
  //   }
  //   100% {
  //     transform: translateY(400px);
  //   }
  // }
  
  // .phone-wrap-animation-inverse {
  //   display: inline-block;
  //   animation: translate-inverse 0.5s linear;
  //   animation-iteration-count: 1;
  //   animation-fill-mode: both;
  // }
  
  // @keyframes translate-inverse {
  //   0% {
  //     transform: translateY(400px);
  //   }
  //   100% {
  //     transform: translateY(0);
  //   }
  // }

  .services-slider {
    /*! purgecss ignore current */
    width: 100%;
    position: relative;
    max-width: 800px;
    margin: auto;
    border-radius: 25px;
    // height: 400px;
    transition: all 0.3s;
  }
  @media screen and (max-width: 992px) {
    /*! purgecss ignore current */
    .services-slider {
      max-width: 680px;
      height: 400px;
      padding: 25px;
    }
  }
  @media screen and (max-width: 768px) {
    /*! purgecss ignore current */
    .services-slider {
      height: auto;
      margin: auto;
    }
  }
  @media screen and (max-height: 500px) and (min-width: 992px) {
    /*! purgecss ignore current */
    .services-slider {
      height: 350px;
    }
  }
  .services-slider-item {
    /*! purgecss ignore current */
    display: flex;
    align-items: center;
    height: 350px;
  }

  @media screen and (max-width: 768px) {
    /*! purgecss ignore current */
    .services-slider-item {
      flex-direction: column;
    }
  }
  .services-slider-item.swiper-slide-active .services-slider-img img {
    /*! purgecss ignore current */
    opacity: 1;
    transition-delay: 0.3s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > * {
    /*! purgecss ignore current */
    opacity: 1;
    transform: none;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(1) {
    /*! purgecss ignore current */
    transition-delay: 0.3s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(2) {
    /*! purgecss ignore current */
    transition-delay: 0.4s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(3) {
    /*! purgecss ignore current */
    transition-delay: 0.5s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(4) {
    /*! purgecss ignore current */
    transition-delay: 0.6s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(5) {
    /*! purgecss ignore current */
    transition-delay: 0.7s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(6) {
    /*! purgecss ignore current */
    transition-delay: 0.8s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(7) {
    /*! purgecss ignore current */
    transition-delay: 0.9s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(8) {
    /*! purgecss ignore current */
    transition-delay: 1s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(9) {
    /*! purgecss ignore current */
    transition-delay: 1.1s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(10) {
    /*! purgecss ignore current */
    transition-delay: 1.2s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(11) {
    /*! purgecss ignore current */
    transition-delay: 1.3s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(12) {
    /*! purgecss ignore current */
    transition-delay: 1.4s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(13) {
    /*! purgecss ignore current */
    transition-delay: 1.5s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(14) {
    /*! purgecss ignore current */
    transition-delay: 1.6s;
  }
  .services-slider-item.swiper-slide-active .services-slider-content > *:nth-child(15) {
    /*! purgecss ignore current */
    transition-delay: 1.7s;
  }
  .services-slider-img {
    /*! purgecss ignore current */
    width: 200px;
    flex-shrink: 0;
    height: 200px;
    background: rgb(27, 47, 69);
    border-radius: 20px;
    transform: translateX(-80px);
    overflow: hidden;
  }
  .services-slider-img:after {
    /*! purgecss ignore current */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 47, 69, 0.3);
    border-radius: 20px;
    opacity: 0.8;
  }
  .services-slider-img img {
    /*! purgecss ignore current */
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    opacity: 0;
    border-radius: 20px;
    transition: all 0.3s;
  }
  @media screen and (max-width: 768px) {
    /*! purgecss ignore current */
    .services-slider-img {
      transform: translateY(-50%);
      width: 90%;
    }
  }
  @media screen and (max-width: 576px) {
    /*! purgecss ignore current */
    .services-slider-img {
      width: 95%;
    }
  }
  @media screen and (max-height: 500px) and (min-width: 992px) {
    /*! purgecss ignore current */
    .services-slider-img {
      height: 270px;
    }
  }
  .services-slider-content {
    /*! purgecss ignore current */
    padding-right: 25px;
  }
  @media screen and (max-width: 768px) {
    /*! purgecss ignore current */
    .services-slider-content {
      margin-top: -80px;
      text-align: center;
      padding: 0 30px;
    }
  }
  @media screen and (max-width: 576px) {
    /*! purgecss ignore current */
    .services-slider-content {
      padding: 0;
    }
  }
  .services-slider-content > * {
    /*! purgecss ignore current */
    opacity: 0;
    transform: translateY(25px);
    transition: all 0.4s;
  }
  .services-slider-title {
    /*! purgecss ignore current */
    font-size: 24px;
    font-weight: 700;
    color: rgb(3, 41, 116);
    margin-bottom: 20px;
  }
  .services-slider-text {
    /*! purgecss ignore current */
    color: #4e4a67;
    margin-bottom: 30px;
    line-height: 1.5em;
  }
  
  @media screen and (max-width: 992px) {
    /*! purgecss ignore current */
    .services-slider-title {
      margin-top: 20px;
    }
  }
  .swiper-pagination-bullet {
    /*! purgecss ignore current */
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }
  
  .swiper-pagination-bullet-active {
    /*! purgecss ignore current */
    color: #fff;
    background: #007aff;
  }

}
