/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/

.contact, .join-us {
  position: relative;
  
  &.contact {
    background: url("./images/contact-bg.webp") left top no-repeat;
    background-size: contain;
  }
  
  @media (max-width: 640px) {
    background-position: center 50px;
  }

  &:before {
    content: "";
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  .info-item {

    & + .info-item {
      margin-top: 40px;
    }

    .contact-icon {
      font-size: 20px;
      background: var(--color-primary);
      color: #fff;
      width: 44px;
      min-width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      transition: all 0.3s ease-in-out;
      margin-right: 15px;
    }

    .contact-icon a {
      color: #fff;
      text-decoration: none;
    }

    h3 {
      padding: 0;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px;
      color: darken($primary, 20);
    }

    p {
      padding: 0;
      margin-bottom: 0;
      font-size: 14px;
    }

    a {
      color: var(--bs-body-color);
      text-decoration: none;
    }

  }


  .php-email-form {
    width: 100%;

    .form-group {
      padding-bottom: 8px;
    }
    
    .error-message {
      display: none;
      color: #fff;
      background: #df1529;
      text-align: left;
      padding: 15px;
      font-weight: 600;
      & br + br {
        margin-top: 25px;
      }
    }
    
    .sent-message {
      display: none;
      color: #fff;
      background: #059652;
      text-align: center;
      padding: 15px;
      font-weight: 600;
    }
  
    .loading {
      display: none;
      background: #fff;
      text-align: center;
      padding: 15px;
  
      &:before {
        content: "";
        display: inline-block;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin: 0 10px -6px 0;
        border: 3px solid #059652;
        border-top-color: #fff;
        animation: animate-loading 1s linear infinite;
      }
  
    }

    input, textarea, select {
      border-radius: 8px;
      box-shadow: none;
      font-size: 14px;
      padding: 12px 15px;
      &:focus{
        border-color:var(--color-primary);
      }
    }


    textarea {
      padding: 10px 12px;
    }

    button[type="button"], button[type="submit"] {
      font-family: var(--font-default);
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 12px 40px;
      border-radius: 50px;
      border-style: none;
      transition: 0.5s;
      margin: 10px;
      color: #fff;
      background: darken($primary, 10);
      &:hover {
        background: var(--color-primary);
      }
    }

    select {
      color: #787878;
    }
    option:not(:first-of-type) {
      color: black;
    }

  }

  /*! purgecss start ignore */
  @keyframes animate-loading {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  /*! purgecss end ignore */
}
