/*--------------------------------------------------------------
# Whatsapp chat classes
--------------------------------------------------------------*/

.whatsapp-icon {
  margin-top:9px;
}

#sy-whatshelp {
  right: 8px;
  bottom: 10px;
  position: fixed;
  z-index: 9999;
}

#sy-whatshelp a svg {
  background-color: #25D366;
  color: #fff;
  line-height: 55px;
  cursor: pointer;
  padding: 13px 17px;
  font-size: 23px;
  box-shadow: 2px 2px 8px -3px #000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
}

#sy-whatshelp a i:hover {
  background: #075E54;
}

/*--------------------------------------------------------------
# recaptcha chat classes
--------------------------------------------------------------*/

.grecaptcha-badge{
  bottom: 75px !important;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  font-size: 14px; 

  .footer-content {
    background-color: $secondary;
    background-size: contain;
    padding: 30px 0 10px 0;

    .footer-info {
      margin-bottom: 30px;
      .logo {
        line-height: 0;
        margin-bottom: 25px;
        img {
          max-height: 40px;
          margin-right: 6px;
        }
    
        span {
          font-size: 30px;
          font-weight: 700;
          letter-spacing: 1px;
          color: var(--color-secondary);
          font-family: var(--font-secondary);
          margin-top: 3px;
        }
    
      }

      p {
        font-size: 15px;
        margin-bottom: 0;
        font-family: var(--font-primary);
        color: var(--color-secondary);
      }
    }

    .social-links {
      @media (min-width: 768px) {
        // margin-left: 50px;
      }

      a {
        font-size: 14px;
        line-height: 0;
        display: inline-block;
        width: 32px;
        height: 32px;
        color: rgba($secondary, .5);
        margin-right: 10px;
        transition: 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 50px;
        background: darken($primary, 10);
        &:hover {
          background: var(--color-primary);
        }
      }
    }

    h4 {
      font-size: 16px;
      font-weight: bold;
      color: lighten($secondary, 10);
      position: relative;
      padding-bottom: 12px;
    }

    .footer-links {
      margin-bottom: 30px;
      ul{
        list-style: none;
        padding: 0;
        margin: 0;

        i {
          margin-right: 2px;
          color: var(--color-primary);
          font-size: 16px;
          line-height: 0;
        }

        li {
          padding: 10px 0;
          &:first-child {
            padding-top: 0;
          }
          display: flex;
          align-items: center;
        }

        a {
          color: lighten($secondary, 15);
          transition: 0.3s;
          display: inline-block;
          line-height: 1;
          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }

    .footer-contact {
      p {
        line-height: 26px;
        color: var(--color-secondary);
      }
    }

  }

  .footer-legal {
    background: #fff;
    padding: 30px 0;
    .copyright {
      text-align: center;
      color: var(--color-secondary);
    }
  
    .credits {
      padding-top: 4px;
      text-align: center;
      font-size: 13px;
      color: var(--color-secondary);
    }
  }

  .logo-alamano{
    background: url("./images/logos/logo-white-2.webp") center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 70px;
  }

  .logo-ort{
    background: url("./images/logos/logo-cie-white.webp") center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 55px;
  }

  .logo-anii{
    background: url("./images/logos/logo-anii-white.webp") center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 40px;
  }

  .logo-ande{
    background: url("./images/logos/logo-ande-white.webp") center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 40px;
  }

  .myth {
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    
    a {
      color: $secondary !important;
    }
  }
}
