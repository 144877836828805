/*-=-=-=-=-=-=-=-=-=-=-=- */
/* Column Grids */
/*-=-=-=-=-=-=-=-=-=-=-=- */

.col_half { width: 49%; }
.col_third { width: 25%; }
.col_fourth { width: 23.5%; }
.col_fifth { width: 18.4%; }
.col_sixth { width: 15%; }
.col_three_fourth { width: 74.5%;}
.col_twothird{ width: 66%;}
.col_half,
.col_third,
.col_twothird,
.col_fourth,
.col_three_fourth,
.col_fifth{
  position: relative;
  display:inline;
  display: inline-block;
  float: left;
  margin-bottom: 20px;
  text-align: center;
  color: $secondary;
}
.end { margin-right: 0 !important; }
/* Column Grids End */

.counter { padding: 20px 0; border-radius: 5px;}
.count-title { font-size: 60px; font-weight: bold;  margin-top: 10px; margin-bottom: 0; text-align: center; }
.count-text { font-size: 18px; font-weight: bold;  margin-top: 10px; margin-bottom: 0; text-align: center; }

.icon{
  font-size: 30px;
  color: $primary;
}

.numbers-section {
  padding: 50px 0 30px 0;
  position: relative;
  background-color: lighten($color: $secondary, $amount: 70);;
}

.skewed {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f6ff;
  z-index: 0;
  transform: skewY(2deg);
  transform-origin: top right;
}